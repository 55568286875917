import * as React from 'react'
import cn from 'classnames'
import { PropsWithoutChildren } from 'src/design-system'
import { IconProps } from '@phosphor-icons/react'

export type MenuSectionTitleProps = {
  children: React.ReactNode
  icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >
} & PropsWithoutChildren<React.HTMLAttributes<HTMLDivElement>>

export const MenuSectionTitle: React.VFC<MenuSectionTitleProps> = (props) => {
  const { children, className, icon, ...restProps } = props

  const Icon = icon

  return (
    <div
      className={cn(
        'px-2 mb-1 text-xs text-gray-600 font-semibold flex flex-row items-center gap-x-1.5',
        className
      )}
      {...restProps}
    >
      {Icon && (
        <Icon
          aria-hidden
          className="flex-0 h-3.5 text-gray-600 w-3.5 flex-shrink-0"
          weight="bold"
        />
      )}
      {children}
    </div>
  )
}
