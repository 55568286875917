import * as React from 'react'
import cn from 'classnames'
import { baseItemClassName, BaseItemProps } from './shared'

export type ButtonItemProps = BaseItemProps &
  Pick<React.ComponentPropsWithoutRef<'button'>, 'onClick'>

export const ButtonItem = React.forwardRef<HTMLButtonElement, ButtonItemProps>(
  (props, ref) => {
    const { onClick, children, icon, className, bold, active } = props

    const Icon = icon

    return (
      <span
        ref={ref}
        className={cn(baseItemClassName({ bold, active }), className)}
        onClick={onClick}
      >
        {Icon && (
          <Icon
            aria-hidden
            className="shrink-0 h-3.5 text-gray-600 w-5"
            weight="bold"
          />
        )}
        {children}
      </span>
    )
  }
)

ButtonItem.displayName = 'ButtonItem'
