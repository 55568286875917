import * as React from 'react'
import cn from 'classnames'
import styles from './MediaContent.module.scss'

type BaseProps = React.ComponentPropsWithoutRef<'div'>

type MediaContentProps = BaseProps & {
  type: 'mobile' | 'desktop'
}

const MediaContent: React.VFC<MediaContentProps> = (props) => {
  const { className, children, type, ...restProps } = props

  return (
    <div className={cn(styles[type], className)} {...restProps}>
      {children}
    </div>
  )
}

export type MobileContentProps = BaseProps

export const MobileContent: React.VFC<MobileContentProps> = (props) => (
  <MediaContent type="mobile" {...props} />
)

export type DesktopContentProps = BaseProps

export const DesktopContent: React.VFC<DesktopContentProps> = (props) => (
  <MediaContent type="desktop" {...props} />
)
