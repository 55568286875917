import * as React from 'react'
import cn from 'classnames'
import { PropsWithoutChildren } from 'src/design-system'

export type MenuSectionProps = {
  children: React.ReactNode
} & PropsWithoutChildren<React.HTMLAttributes<HTMLDivElement>>

export const MenuSection: React.VFC<MenuSectionProps> = (props) => {
  const { children, className, ...restProps } = props
  return (
    <section className={cn('flex flex-col mb-4', className)} {...restProps}>
      {children}
    </section>
  )
}
