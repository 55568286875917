import * as React from 'react'
import cn from 'classnames'

export type BodyProps = {
  children: React.ReactNode
  className?: string
}

export const Body: React.VFC<BodyProps> = (props) => {
  const { children, className } = props

  return (
    <div
      className={cn('flex flex-col px-3 py-4 overflow-auto h-full', className)}
    >
      {children}
    </div>
  )
}
