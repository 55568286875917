import * as React from 'react'
import { motion } from 'framer-motion'

export type RootProps = {
  children: React.ReactNode
  /**
   * Used for manually controlling whether it's open or not - changing this will animate the width of the side menu
   */
  isOpen?: boolean
}

export const Root: React.VFC<RootProps> = (props) => {
  const { children, isOpen = true } = props

  return (
    <motion.div
      initial={isOpen ? 'open' : 'closed'}
      animate={isOpen ? 'open' : 'closed'}
      variants={{
        open: { width: '291px', transition: { duration: 0.25 } },
        closed: { width: '55px', transition: { duration: 0.25 } },
      }}
      className="h-full overflow-hidden relative bg-gray-25 border-0 border-gray-100 border-r border-solid"
    >
      <div className="flex flex-col h-full lg:h-screen justify-between overflow-auto w-[291px] bg-gray-25 radius-r-sm">
        {children}
      </div>
    </motion.div>
  )
}
