import { Link } from 'src/design-system'
import * as React from 'react'
import { WithAnalytics, WithAnalyticsProps } from 'components/with-analytics'
import cn from 'classnames'
import { baseItemClassName, BaseItemProps } from './shared'

export type LinkItemProps = BaseItemProps & {
  analyticsProps?: Omit<WithAnalyticsProps, 'children'>
  href: string
  isExternal?: boolean
  enableTurbo?: boolean
}

export const LinkItem: React.VFC<LinkItemProps> = (props) => {
  const {
    active,
    analyticsProps,
    bold,
    children,
    className,
    href,
    icon,
    isExternal,
    showActiveStyling = true,
    enableTurbo = true,
    ...restProps
  } = props

  const Icon = icon

  const turboProps = enableTurbo
    ? {
        'data-turbo-frame': 'content',
        'data-turbo-action': 'advance',
      }
    : {}

  return (
    <WithAnalytics {...analyticsProps}>
      <Link
        className={cn(
          baseItemClassName({ active, bold, showActiveStyling }),
          className
        )}
        href={href}
        isExternal={isExternal}
        underline={false}
        {...turboProps}
        {...restProps}
      >
        {Icon && (
          <Icon
            aria-hidden
            className="flex-0 h-3.5 text-gray-600 w-5 flex-shrink-0"
            weight="bold"
          />
        )}
        {children}
      </Link>
    </WithAnalytics>
  )
}
