import cn from 'classnames'
import { IconProps } from '@phosphor-icons/react'

export type BaseItemProps = {
  className?: string
  // TODO: figure out what type this can be
  icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >
  children: React.ReactNode
  /**
   * Whether or not the entire thing should be bold/black - used for header items
   */
  bold?: boolean
  active?: boolean
  showActiveStyling?: boolean
}

export const baseItemClassName = (
  props: Pick<BaseItemProps, 'bold' | 'active' | 'showActiveStyling'>
) => {
  const { bold, active, showActiveStyling = true } = props

  return cn(
    active || bold ? 'text-gray-900' : 'text-gray-600',
    showActiveStyling && active
      ? 'bg-gray-50 border-gray-50'
      : 'border-transparent',
    bold ? 'font-bold' : 'font-normal',
    'focus-visible:!ring-theme-50 focus-visible:!ring-2 outline-none min-h-[2rem] py-1 px-2 hover:border-gray-100 border-px border-solid flex flex-row items-center gap-x-2 w-full rounded transition-colors duration-200 text-sm hover:text-gray-900'
  )
}
